import React from "react";

function Me() {
  return (
    <div className="Me">
      <h2>Who am I?</h2>
      <div className="flex justify-center align-center half-half">
        <span>
          <p>
            Hi, I’m Luke De-Ath, a self-taught aspiring web developer based in
            Bolton, Greater Manchester.
          </p>
          <p>
            I’m passionate about building <strong>customisable</strong> websites
            from scratch, allowing for unique designs without relying on
            templates or website builders.
          </p>
          <p>
            In my free time, I’m dedicated to expanding my skills and creating
            tailored web pages for others.
          </p>
          <p>Age: 23</p>
        </span>
        <img
          src="/Images/Me.jpg"
          alt="Luke De-Ath smiling in an outdoor setting."
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default Me;
