import React from "react";
import { NavLink } from "react-router-dom";

function NavBar() {
  return (
    <nav>
      <ul>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>

        <li className="contact">
          <NavLink to="/enquiry">Contact Me</NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
